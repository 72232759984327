<template>
  <div class="symptom-table">
    <v-simple-table dense fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="32px" class="pa-1" style="border-right: thin solid rgba(0, 0, 0, 0.12)" />
            <th class="text-center">治療選項</th>
            <th class="text-center">內容</th>
            <th class="text-center">備註</th>
            <th class="text-right">編輯</th>
          </tr>
        </thead>
        <tbody v-for="(row, row_i) in symptomTable.simpleTable" :key="`row${row_i}`">
          <tr v-for="(data, data_i) in row" :key="`data${data_i}`">
            <template v-if="typeof data[0] === 'object'">
              <td
                :rowspan="data[0].rowspan"
                class="pa-1"
                style="border-right: thin solid rgba(0, 0, 0, 0.12)"
              >
                <v-btn fab x-small dark depressed color="primary" @click="addContent(data[0])">
                  <span class="text-subtitle-2">{{ data[0].name }}</span>
                </v-btn>
              </td>
              <td v-for="n in 3" :key="`spanrow_${n}`">{{ data[n] }}</td>
              <td class="text-right">
                <v-btn
                  fab
                  x-small
                  dark
                  text
                  depressed
                  color="error"
                  @click="deleteOption(row_i, data_i)"
                  v-if="data[4]"
                >
                  <v-icon>{{ data[4] }}</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-else>
              <td v-for="n in 3" :key="`row${n}`">{{ data[n - 1] }}</td>
              <td class="text-right">
                <v-btn
                  fab
                  x-small
                  dark
                  text
                  depressed
                  @click="deleteOption(row_i, data_i)"
                  color="error"
                >
                  <v-icon>{{ data[3] }}</v-icon>
                </v-btn>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <add-symptom-dialog
      v-model="openAddDialog"
      :item="addInfo"
      :selected="selected"
      @add-array="addData = $event"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import extensions from '@/mixins/extensions';
import AddSymptomDialog from '@/components/Diagnosis/AddSymptomDialog';
import TableTransform from '@/models/tableTransform';

export default Vue.component(
  'symptom-table',
  Vue.extend({
    name: 'SymptomTable',
    mixins: [extensions],
    props: {
      value: { type: Object, default: () => new Object() },
    },
    components: { AddSymptomDialog },
    data: () => ({
      symptomTable: new TableTransform(),
      addData: [],
      requesting: false,
      dialog: false,
      addInfo: null,
      openAddDialog: false,
      pickedMethodIdx: null,
      currentPickedMethod: null,
      selected: [],
    }),
    computed: {
      diagnosisType() {
        if (this.$store.state.diagnosisInfo) {
          if (Object.keys(this.value).length === 0) {
            this.$store.state.diagnosisInfo.methods
              .map((item) => item.name)
              .forEach((method) => (this.value[method] = {}));
          }
          return this.$store.state.diagnosisInfo.methods;
        }
        return [];
      },
    },
    watch: {
      addData(val) {
        if (val) {
          this.value[this.currentPickedMethod] = {
            ...this.value[this.currentPickedMethod],
            ...val,
          };
          this.$emit('input', this.value);
          this.symptomTable.update(this.diagnosisType, this.value);
          this.$store.state.tempSymptom = Object.assign({}, this.symptomTable.tableData);
        }
      },
    },
    methods: {
      addContent(item) {
        this.currentPickedMethod = item.name;
        this.addInfo = item.options;
        this.openAddDialog = true;
      },
      deleteOption(method_i, option_i) {
        const method = Object.values(this.value)[method_i];
        const option = Object.keys(method)[option_i];
        delete method[option];
        this.symptomTable.update(this.diagnosisType, this.value);
        this.$store.state.tempSymptom = Object.assign({}, this.symptomTable.tableData);
      },
    },
    async created() {
      await this.$store.dispatch('bindDiagnosisInfo', this.$store.state.diagnosisId);
      await this.$store.dispatch('fetchPatientSymptom', this.$route.params.medicalOrderNumber);
      if (Object.keys(this.$store.state.tempSymptom).length > 0) {
        this.value = Object.assign(this.value, this.$store.state.tempSymptom);
      }
      this.symptomTable.update(this.diagnosisType, this.value);
    },
  }),
);
</script>
