<template>
  <div class="Diagnosis" v-if="currentPatient">
    <v-toolbar flat dense>
      <v-row>
        <v-col
          :class="!$vuetify.breakpoint.mobile ? 'text-subtitle-2' : 'text-body-2 px-0'"
          cols="3"
          sm="2"
        >
          病歷號：{{ currentPatient.medicalOrderNumber }}
        </v-col>
        <v-col
          :class="!$vuetify.breakpoint.mobile ? 'text-subtitle-2' : 'text-body-2 px-0'"
          cols="3"
          sm="2"
        >
          姓名：{{ currentPatient.name }}
        </v-col>
        <template v-if="!$vuetify.breakpoint.mobile">
          <v-col cols="2">
            性別：
            <v-icon v-if="currentPatient.gender === '男'">mdi-gender-male</v-icon>
            <v-icon v-else-if="currentPatient.gender === '女'">mdi-gender-female</v-icon>
            <v-icon v-else>mdi-help</v-icon>
          </v-col>
        </template>
        <v-col
          :class="!$vuetify.breakpoint.mobile ? 'text-subtitle-2' : 'text-body-2 px-0'"
          cols="3"
          sm="2"
        >
          健保：{{ currentPatient.status }}
        </v-col>
        <v-spacer />
        <v-btn rounded outlined class="my-auto" @click.prevent="submit()">
          <v-icon>mdi-check-bold</v-icon>
          完成
        </v-btn>
      </v-row>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12">
              <v-card class="pt-0">
                <v-card-title class="text-h6 pb-0">過去病史</v-card-title>
                <v-card-text class="py-2 mt-0">
                  <v-combobox
                    class="pt-0 mt-0"
                    v-model="patientDiagnosisInfo.medicalHist"
                    :items="diseaseOptions"
                    chips
                    clearable
                    multiple
                    persistent-hint
                    hint="若不在選項中請手動輸入"
                    label="選擇或輸入過去病史"
                  >
                    <template #selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        :ripple="false"
                        small
                        close
                        @click="select"
                        @click:close="remove(patientDiagnosisInfo.medicalHist, item)"
                      >
                        <strong>{{ item }}</strong>
                        &nbsp;
                        <span v-if="!isCurrentItemInOptions(diseaseOptions, item)">（其他）</span>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card style="height: calc(100vh - 284px)">
                <v-card-title>症狀</v-card-title>
                <symptom-table v-model="patientDiagnosisInfo.symptom" />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="text-h6 pb-0">病名與體格</v-card-title>
                <v-card-text class="py-0 mx-0">
                  <v-container fluid>
                    <v-row>
                      <v-col class="pb-0">
                        <v-text-field
                          class="py-0 mt-0"
                          v-model="patientDiagnosisInfo.diseaseName"
                          label="病名"
                          clearable
                          :rules="[inputRules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pb-0">
                        <v-combobox
                          class="py-0 mt-0"
                          v-model="patientDiagnosisInfo.physique"
                          :items="physiqueOptions"
                          clearable
                          small-chips
                          hide-selected
                          :rules="[inputRules.required]"
                          persistent-hint
                          hint="若不在選項中請手動輸入"
                          label="體格"
                        >
                          <template #selection="{ attrs, item, select, selected }">
                            <v-chip
                              v-bind="attrs"
                              :input-value="selected"
                              :ripple="false"
                              small
                              close
                              @click="select"
                              @click:close="patientDiagnosisInfo.physique = ''"
                            >
                              <strong>{{ item }}</strong>
                              <span
                                v-if="!isCurrentItemInOptions(patientDiagnosisInfo.physique, item)"
                              >
                                （其他）
                              </span>
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card style="height: calc(100vh - 270px)">
                <medication-table />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-footer dark padless :fixed="$vuetify.breakpoint.mobile">看診時間：{{ getNow }}</v-footer>
  </div>
</template>

<script>
import Vue from 'vue';

import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';
import SymptomTable from '@/components/Diagnosis/SymptomTable';
import MedicationTable from '@/components/Diagnosis/MedicationTable';
import supabase from '@/plugins/supabase';

export default Vue.extend({
  name: 'Diagnosis',
  components: { SymptomTable, MedicationTable },
  mixins: [extensions, inputRules],
  data: () => ({
    diseaseOptions: ['心臟病', '高血壓', '糖尿病'],
    physiqueOptions: ['瘦弱無力', '瘦但有力', '中等', '虛胖', '壯碩'],
    currentPatient: null,
    patientStoreIdx: null,
    currentPatientDiagnosisInfo: {},
    blankInfo: {
      medicalHist: [],
      physique: '',
      diseaseName: '',
      medicalOrderNumber: '',
    },
  }),
  computed: {
    getNow() {
      const days = ['日', 'ㄧ', '二', '三', '四', '五', '六'];
      const now = new Date();
      const date =
        now.getFullYear() + '/' + now.getMonth() + 1 + '/' + this.padNumber(now.getDate(), 2);
      const time =
        this.padNumber(now.getHours(), 2) +
        ':' +
        this.padNumber(now.getMinutes(), 2) +
        ':' +
        this.padNumber(now.getSeconds(), 2);
      const day = days[now.getDay()];
      return date + ' （' + day + '） ' + time;
    },
    patientDiagnosisInfo() {
      if (this.$store.state.currentPatientDiagnosisInfo.length === 0) {
        return {
          medicalHist: [],
          physique: '',
          diseaseName: '',
        };
      }
      return this.$store.state.currentPatientDiagnosisInfo[0];
    },
  },
  methods: {
    padNumber(num, size) {
      num = num.toString();
      while (num.length < size) num = '0' + num;
      return num;
    },
    remove(arr, item) {
      return arr.splice(arr.indexOf(item), 1);
    },
    isCurrentItemInOptions(arr, item) {
      return arr.indexOf(item) !== -1 ? true : false;
    },
    async submit() {
      this.patientDiagnosisInfo.symptom = this.$store.state.tempSymptom;
      this.patientDiagnosisInfo.medication = this.$store.state.tempMedication;
      for (const [keyName, valueName] of Object.entries(this.patientDiagnosisInfo)) {
        const tempNewData = { id: this.patientDiagnosisInfo.id };
        if (keyName === 'symptom' || keyName === 'medication') {
          tempNewData[keyName] = JSON.stringify(valueName);
        } else {
          tempNewData[keyName] = valueName;
        }
        await this.$store.dispatch('updatePatientDiagnosisInfo', tempNewData);
      }
      return this.$router.push('/');
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   this.$store.commit('patientTempStore', {
  //     idx: this.patientStoreIdx,
  //     data: this.patientDiagnosisInfo,
  //   });
  //   next();
  // },
  async created() {
    // if (this.$store.state.user) {
    //   Object.values(this.$store.state.user.ownPatients).filter(async (item) => {
    //     if (item.medicalOrderNumber === this.$route.params.medicalOrderNumber) {
    //       this.$store.commit('patientId', item.id);
    //       await this.$store.dispatch('bindPatient', this.$store.state.patientId);
    //       this.currentPatient = item;
    //     }
    //   });
    // }
    // this.patientStoreIdx = this.$store.state.patientDiagnosisArray
    //   .map((item) => item.id)
    //   .indexOf(this.$store.state.patientId);
    const param = this.$route.params.medicalOrderNumber;
    const fetchedData = await supabase
      .from('patientInfo')
      .select(`medicalOrderNumber, name, gender, status`)
      .eq('medicalOrderNumber', param);
    this.currentPatient = fetchedData.data[0];
    await this.$store.dispatch('fetchPatientDiagnosisInfoData', param);
    if (this.$store.state.currentPatientDiagnosisInfo.length === 0) {
      this.blankInfo.medicalOrderNumber = this.currentPatient.medicalOrderNumber;
      await this.$store.dispatch('createPatientDiagnosisInfo', this.blankInfo);
    }
    await this.$store.dispatch('fetchPatientMedication', param);
    this.$store.dispatch('subscribeToDiagnosisInfoChange');
  },
  beforeDestroy() {
    this.$store.dispatch('resetTempStore');
  },
});
</script>
