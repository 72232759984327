export default class TableTransform {
  constructor() {
    this._resetTable();
  }

  _resetTable() {
    this.simpleTable = [];
  }

  _setDataTable() {
    const methods = Object.values(this.tableData);
    for (let i = 0; i < methods.length; i++) {
      const options = Object.keys(methods[i]);
      const optionInfos = Object.values(methods[i]);
      const rowspan = options.length;
      let method = [];
      if (rowspan === 0) {
        let option = [this.diagnosisType[i], '', '', ''];
        method.push(option);
      }
      for (let row = 0; row < rowspan; row++) {
        let option = [];
        if (row === 0) {
          this.diagnosisType[i].rowspan = rowspan;
          option.push(this.diagnosisType[i]);
        }
        option.push(options[row]);
        let content = '';
        optionInfos[row].content.forEach((item, i) => {
          content = i === 0 ? item : content + ', ' + item;
        });
        option.push(content);
        option.push(optionInfos[row].remarks);
        option.push('mdi-delete');
        method.push(option);
      }
      this.simpleTable.push(method);
    }
  }

  clear() {
    this._resetTable();
  }

  update(diagnosisType, tableData) {
    this.diagnosisType = diagnosisType;
    this.tableData = tableData;
    this._resetTable();
    this._setDataTable();
  }
}
