<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="this.$store.state.tempMedication"
    height="calc(100vh - 439px)"
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>開藥</v-toolbar-title>
        <v-spacer />
        <v-dialog v-model="dialog" max-width="888px">
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="success" dark class="mb-2">
              <v-icon>mdi-add</v-icon>
              新增
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :items="Object.keys(medicationStocks)"
                      v-model="editedItem.name"
                      label="品項"
                      hide-selected
                    ></v-select>
                    <span v-if="editedItem.name != ''">
                      剩餘： {{ medicationStocks[editedItem.name] }} 錢
                    </span>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :disabled="editedItem.name == ''"
                      v-model="editedItem.amount"
                      label="份量"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :items="['外用', '內服']"
                      :disabled="editedItem.amount == ''"
                      v-model="editedItem.type"
                      label="使用種類"
                      hide-selected
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.remark" label="備註"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">取消</v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="editedItem.amount <= 0 || editedItem.type == ''"
              >
                儲存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">確定要刪除該項目?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">確認</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template #[`item.action`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue';

import extensions from '@/mixins/extensions';
import inputRules from '@/mixins/inputRules';

export default Vue.component(
  'medication-table',
  Vue.extend({
    name: 'MedicationTable',
    mixins: [extensions, inputRules],
    data: () => ({
      headers: [
        {
          text: '品項',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: '份量(錢)',
          sortable: false,
          value: 'amount',
        },
        {
          text: '使用種類',
          sortable: false,
          value: 'type',
        },
        {
          text: '備註',
          sortable: false,
          value: 'remarks',
        },
        {
          text: '編輯',
          sortable: false,
          value: 'action',
        },
      ],
      editedItem: {
        name: '',
        amount: 0,
        type: '',
        remarks: '',
      },
      defaultItem: {
        name: '',
        amount: 0,
        type: '',
        remarks: '',
      },
      diagnosisData: [],
      medicationStocks: {
        六君子湯: 100,
        六味地黃丸: 100,
        川芎: 100,
        柴胡: 100,
        陳皮: 100,
        苓甘薑味辛夏仁湯: 100,
      },
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
    }),
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? '新增項目' : '編輯項目';
      },
    },
    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },
    methods: {
      isCurrentItemInOptions(arr, item) {
        return arr.indexOf(item) !== -1 ? true : false;
      },
      editItem(item) {
        // this.editedIndex = this.diagnosisData.indexOf(item);
        this.editedIndex = this.$store.state.tempMedication.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },
      deleteItem(item) {
        // this.editedIndex = this.diagnosisData.indexOf(item);
        this.editedIndex = this.$store.state.tempMedication.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
      deleteItemConfirm() {
        // const deleteItem = this.diagnosisData[this.editedIndex];
        const deleteItem = this.$store.state.tempMedication[this.editedIndex];
        this.medicationStocks[deleteItem.name] =
          Number(this.medicationStocks[deleteItem.name]) + Number(deleteItem.amount);
        // this.diagnosisData.splice(this.editedIndex, 1);
        this.$store.state.tempMedication.splice(this.editedIndex, 1);
        this.closeDelete();
      },
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
      save() {
        if (this.editedIndex > -1) {
          // const targetItem = this.diagnosisData[this.editedIndex];
          const targetItem = this.$store.state.tempMedication[this.editedIndex];
          const amountDiff = Number(targetItem.amount) - Number(this.editedItem.amount);
          this.medicationStocks[targetItem.name] =
            Number(this.medicationStocks[targetItem.name]) + amountDiff;
          // Object.assign(this.diagnosisData[this.editedIndex], this.editedItem);
          Object.assign(this.$store.state.tempMedication[this.editedIndex], this.editedItem);
        } else {
          // this.diagnosisData.push(this.editedItem);
          this.$store.state.tempMedication.push(this.editedItem);
          this.medicationStocks[this.editedItem.name] =
            this.medicationStocks[this.editedItem.name] - this.editedItem.amount;
        }
        this.close();
      },
    },
    async created() {},
  }),
);
</script>
