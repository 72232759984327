var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentPatient)?_c('div',{staticClass:"Diagnosis"},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-row',[_c('v-col',{class:!_vm.$vuetify.breakpoint.mobile ? 'text-subtitle-2' : 'text-body-2 px-0',attrs:{"cols":"3","sm":"2"}},[_vm._v(" 病歷號："+_vm._s(_vm.currentPatient.medicalOrderNumber)+" ")]),_c('v-col',{class:!_vm.$vuetify.breakpoint.mobile ? 'text-subtitle-2' : 'text-body-2 px-0',attrs:{"cols":"3","sm":"2"}},[_vm._v(" 姓名："+_vm._s(_vm.currentPatient.name)+" ")]),(!_vm.$vuetify.breakpoint.mobile)?[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" 性別： "),(_vm.currentPatient.gender === '男')?_c('v-icon',[_vm._v("mdi-gender-male")]):(_vm.currentPatient.gender === '女')?_c('v-icon',[_vm._v("mdi-gender-female")]):_c('v-icon',[_vm._v("mdi-help")])],1)]:_vm._e(),_c('v-col',{class:!_vm.$vuetify.breakpoint.mobile ? 'text-subtitle-2' : 'text-body-2 px-0',attrs:{"cols":"3","sm":"2"}},[_vm._v(" 健保："+_vm._s(_vm.currentPatient.status)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"my-auto",attrs:{"rounded":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[_c('v-icon',[_vm._v("mdi-check-bold")]),_vm._v(" 完成 ")],1)],2)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-card-title',{staticClass:"text-h6 pb-0"},[_vm._v("過去病史")]),_c('v-card-text',{staticClass:"py-2 mt-0"},[_c('v-combobox',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.diseaseOptions,"chips":"","clearable":"","multiple":"","persistent-hint":"","hint":"若不在選項中請手動輸入","label":"選擇或輸入過去病史"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"ripple":false,"small":"","close":""},on:{"click":select,"click:close":function($event){return _vm.remove(_vm.patientDiagnosisInfo.medicalHist, item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v(" "),(!_vm.isCurrentItemInOptions(_vm.diseaseOptions, item))?_c('span',[_vm._v("（其他）")]):_vm._e()])]}}],null,false,4217750112),model:{value:(_vm.patientDiagnosisInfo.medicalHist),callback:function ($$v) {_vm.$set(_vm.patientDiagnosisInfo, "medicalHist", $$v)},expression:"patientDiagnosisInfo.medicalHist"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"height":"calc(100vh - 284px)"}},[_c('v-card-title',[_vm._v("症狀")]),_c('symptom-table',{model:{value:(_vm.patientDiagnosisInfo.symptom),callback:function ($$v) {_vm.$set(_vm.patientDiagnosisInfo, "symptom", $$v)},expression:"patientDiagnosisInfo.symptom"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 pb-0"},[_vm._v("病名與體格")]),_c('v-card-text',{staticClass:"py-0 mx-0"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-text-field',{staticClass:"py-0 mt-0",attrs:{"label":"病名","clearable":"","rules":[_vm.inputRules.required]},model:{value:(_vm.patientDiagnosisInfo.diseaseName),callback:function ($$v) {_vm.$set(_vm.patientDiagnosisInfo, "diseaseName", $$v)},expression:"patientDiagnosisInfo.diseaseName"}})],1),_c('v-col',{staticClass:"pb-0"},[_c('v-combobox',{staticClass:"py-0 mt-0",attrs:{"items":_vm.physiqueOptions,"clearable":"","small-chips":"","hide-selected":"","rules":[_vm.inputRules.required],"persistent-hint":"","hint":"若不在選項中請手動輸入","label":"體格"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"ripple":false,"small":"","close":""},on:{"click":select,"click:close":function($event){_vm.patientDiagnosisInfo.physique = ''}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),(!_vm.isCurrentItemInOptions(_vm.patientDiagnosisInfo.physique, item))?_c('span',[_vm._v(" （其他） ")]):_vm._e()])]}}],null,false,1077234316),model:{value:(_vm.patientDiagnosisInfo.physique),callback:function ($$v) {_vm.$set(_vm.patientDiagnosisInfo, "physique", $$v)},expression:"patientDiagnosisInfo.physique"}})],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"height":"calc(100vh - 270px)"}},[_c('medication-table')],1)],1)],1)],1)],1)],1),_c('v-footer',{attrs:{"dark":"","padless":"","fixed":_vm.$vuetify.breakpoint.mobile}},[_vm._v("看診時間："+_vm._s(_vm.getNow))])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }