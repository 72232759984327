<template>
  <v-dialog persistent :value="value" @input="$emit('input', $event)" max-width="500px">
    <v-card>
      <v-toolbar dense dark class="primary">
        <v-toolbar-title>新增症狀</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-radio-group mandatory v-if="item" v-model="addItem.option">
                <v-radio
                  v-for="(op, i) in Object.keys(item)"
                  :key="`option${i}`"
                  :label="op"
                  :value="op"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-card-text>
                <v-checkbox
                  dense
                  multiple
                  hide-details
                  v-model="addItem.content"
                  v-for="(content, i) in currentContentOptions"
                  :key="`content${i}`"
                  :label="content"
                  :value="content"
                  class="mt-0"
                ></v-checkbox>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-text-field
              clearable
              label="備註"
              prepend-icon="mdi-note"
              v-model="addItem.remarks"
            ></v-text-field>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- <v-window v-model="step">
        <v-window-item>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-radio-group mandatory v-if="item" v-model="addItem.option">
                    <v-radio
                      v-for="(op, i) in Object.keys(item)"
                      :key="`option${i}`"
                      :label="op"
                      :value="op"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-card-text>
                    <v-checkbox
                      dense
                      multiple
                      hide-details
                      v-model="addItem.content"
                      v-for="(content, i) in currentContentOptions"
                      :key="`content${i}`"
                      :label="content"
                      :value="content"
                      class="mt-0"
                    ></v-checkbox>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-text-field
                  clearable
                  label="備註"
                  prepend-icon="mdi-note"
                  v-model="addItem.remarks"
                ></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
        </v-window-item>
        <v-window-item>
          <v-card-text>
            <v-checkbox
              dense
              multiple
              v-model="addItem.content"
              v-for="(content, i) in currentContentOptions"
              :key="`content${i}`"
              :label="content"
              :value="content"
            ></v-checkbox>
          </v-card-text>
        </v-window-item>
        <v-window-item>
          <v-card-text>
            <v-text-field
              clearable
              label="備註"
              prepend-icon="mdi-note"
              v-model="addItem.remarks"
            ></v-text-field>
          </v-card-text>
        </v-window-item>
      </v-window> -->
      <v-divider />
      <v-card-actions>
        <v-btn text color="red accent-2" @click="close()">取消</v-btn>
        <v-spacer />
        <v-btn
          text
          :disabled="this.addItem.content.length === 0"
          color="info"
          @click="addOneMore()"
        >
          新增下一筆
        </v-btn>
        <v-btn text :disabled="this.addItem.content.length === 0" color="info" @click="submit()">
          確認新增
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from 'vue';

import extensions from '@/mixins/extensions';

export default Vue.component(
  'add-symptom-dialog',
  Vue.extend({
    name: 'AddSymptomialog',
    mixins: [extensions],
    props: {
      value: { type: Boolean, default: true },
      item: { type: Object, default: () => new Object() },
      selected: { type: Array, default: () => new Array() },
    },
    data: () => ({
      addItem: {
        option: '',
        content: [],
        remarks: '',
      },
      requesting: false,
      openAddDialog: false,
    }),
    computed: {
      currentContentOptions() {
        if (this.addItem.option) {
          return this.item[this.addItem.option];
        }
        return [];
      },
    },
    methods: {
      submit() {
        const picked = {};
        picked[this.addItem.option] = {
          content: this.addItem.content,
          remarks: this.addItem.remarks,
        };
        this.$emit('add-array', picked);
        this.close();
      },
      addOneMore() {
        const picked = {};
        picked[this.addItem.option] = {
          content: this.addItem.content,
          remarks: this.addItem.remarks,
        };
        this.$emit('add-array', picked);
        this._init_data();
      },
      close() {
        this._init_data();
        this.$emit('input', false);
      },
      _init_data() {
        this.addItem = {
          option: '',
          content: [],
          remarks: '',
        };
      },
    },
  }),
);
</script>
